import TehoSvg from '../../assets/images/teho.png';
import InlancerSvg from '../../assets/images/inlancer.png';
import RacemantraSvg from '../../assets/images/racetantra.png';
import ABC1Svg from '../../assets/images/abc1.png';
import TehoBrandingPng from '../../assets/images/f1.jpeg';
export const clients = [
  {
    id: 1,
    name: 'Teho',
    image: TehoSvg,
    projects: [true, true, true, true, true],
    projectsDetails: {
      category1: {
        projectId: 1,
        image: TehoBrandingPng,
        duration: '5 Days',
        budget: '$3000-$5000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'https://www.projectteho.com',
      },
      category2: {
        projectId: 2,
        image: TehoBrandingPng,
        duration: '3 Days',
        budget: '$7000-$9000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category3: {
        projectId: 3,
        image: TehoBrandingPng,
        duration: '15 Days',
        budget: '$30000-$35000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category4: {
        projectId: 4,
        image: TehoBrandingPng,
        duration: '5 Days',
        budget: '$3000-$5000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category5: {
        projectId: 5,
        image: TehoBrandingPng,
        duration: '50 Days',
        budget: '$50000-$55000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
    },
  },
  {
    id: 2,
    name: 'Inlancer',
    image: InlancerSvg,
    projects: [true, true, false, true, true],
    projectsDetails: {
      category1: {
        projectId: 1,
        image: TehoBrandingPng,
        duration: '5 Days',
        budget: '$3000-$5000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category2: {
        projectId: 2,
        image: TehoBrandingPng,
        duration: '3 Days',
        budget: '$7000-$9000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category3: {
        projectId: 3,
        image: TehoBrandingPng,
        duration: '15 Days',
        budget: '$30000-$35000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category4: {
        projectId: 4,
        image: TehoBrandingPng,
        duration: '5 Days',
        budget: '$3000-$5000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category5: {
        projectId: 5,
        image: TehoBrandingPng,
        duration: '50 Days',
        budget: '$50000-$55000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
    },
  },
  {
    id: 4,
    name: 'Lazy Idli Pvt Ltd',
    image: ABC1Svg,
    projects: [true, true, true, true, true],
    projectsDetails: {
      category1: {
        projectId: 1,
        image: TehoBrandingPng,
        duration: '5 Days',
        budget: '$3000-$5000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category2: {
        projectId: 2,
        image: TehoBrandingPng,
        duration: '3 Days',
        budget: '$7000-$9000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category3: {
        projectId: 3,
        image: TehoBrandingPng,
        duration: '15 Days',
        budget: '$30000-$35000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category4: {
        projectId: 4,
        image: TehoBrandingPng,
        duration: '5 Days',
        budget: '$3000-$5000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
      category5: {
        projectId: 5,
        image: TehoBrandingPng,
        duration: '50 Days',
        budget: '$50000-$55000',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a, eu amet, amet diam, odio sed. Curabitur egestas et scelerisque semper arcu morbi facilisi. Ac vel quis id laoreet ultrices gravida luctus',
        webLink: 'www.projectteho.com',
      },
    },
  },
];
