// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/gif_landing.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container {\n    position: relative;\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center;\n    background-size: cover;\n}\n\n\n@media screen and (min-width: 0px) and (max-width: 450px) {\n    #stickyClone {\n        position: relative !important\n    }\n}\n\n@media only screen and (min-width: 1050px) and (max-width: 1280px) {\n    #stickyContainer {\n        height: 170px !important;\n    }\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/pages/landing/landing.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,0DAA6D;IAC7D,sBAAsB;AAC1B;;;AAGA;IACI;QACI;IACJ;AACJ;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".container {\n    position: relative;\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    background: url('../../assets/images/gif_landing.gif') center;\n    background-size: cover;\n}\n\n\n@media screen and (min-width: 0px) and (max-width: 450px) {\n    #stickyClone {\n        position: relative !important\n    }\n}\n\n@media only screen and (min-width: 1050px) and (max-width: 1280px) {\n    #stickyContainer {\n        height: 170px !important;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
